.subItem {
  position: relative;
  margin-left: 15px;
}

.subItem::before {
  content: '';
  position: absolute;
  width: 1px;
  display: block;
  height: 100%;
  top: -8px;
  left: 0;
}

.subItem::after {
  position: absolute;
  content: '';
  width: 13px;
  min-width: 13px;
  height: 1px;
  top: calc(50% + 4px);
}

.subItem:last-child::before {
  height: calc(50% + 12px);
}

.element {
  width: calc(100% - 13px);
  margin-left: 13px;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}

.dark {
  .subItem::before {
    border-right: 1px solid var(--grey4);
  }
  .subItem::after {
    background-color: var(--grey4);
  }
}

.light {
  .subItem::before {
    border-right: 1px solid var(--grey15);
  }
  .subItem::after {
    background-color: var(--grey15);
  }
}