.content {
  padding: 15px;
  border-radius: 6px;
  width: 100%;
}

.slots {
  margin-top: 10px;
}

.light {
  &.content {
    background-color: var(--white);
    color: var(--black);
  }
  .accordionWrap {
    padding-bottom: 0;
    border-bottom: none;
  }
  .accordionToggleOpen {
    border-bottom: 1px solid var(--grey17);
  }
}

.dark {
  &.content {
    background-color: var(--black14);
    color: var(--white);
  }
  .accordionWrap {
    padding-bottom: 0;
    border-bottom: none;
  }
  .accordionToggleOpen {
    border-bottom: 1px solid var(--grey1);
  }
}

.line {
  margin-bottom: 7px;
}

.accordionIconWrap {
  padding-right: 8px;
}

.accordionToggleInner {
  padding: 10px 0 0 0;
}

.accordionToggleOpen {
  .accordionToggleInner {
    padding: 10px 0 16px 0;
  }
}