.content {
  gap: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: auto;
  padding-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

@media screen and (max-width: 3550px) {
  .content {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (max-width: 3160px) {
  .content {
    grid-template-columns: repeat(4, 1fr);
  }
}


@media screen and (max-width: 2680px) {
  .content {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1840px) {
  .content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1390px) {
  .content {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 1120px) {
  .content {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media screen and (max-width: 1035px) {
  .content {
    grid-template-columns: repeat(1, 1fr);
  }
}