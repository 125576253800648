.progress {
  display: flex;
  height: 17px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  border-radius: 4px;
}

.progressBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--white);
  text-align: center;
  white-space: nowrap;
  background-color: var(--blue);
  transition: width 0.6s ease;
  &-process {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}

.dark {
  &.progress {
    background-color: var(--black);
  } 
}

.light {
  &.progress {
    background-color: var(--white);
  } 
}