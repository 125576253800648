.values {
  margin: 0 16px;
}

.dark {
  .values {
    color: var(--white);
  }
}

.light {
  .values {
    color: var(--black);
  }
}