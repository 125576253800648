.dialog {
  min-width: 377px;
}

.body {
  padding: 22px;
}

.title {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.content {
  margin-bottom: 30px;
  font-size: 12px;
  text-align: center;
}

.backBtn {
  width: 70px;
}

.replaceBtn {
  width: 94px;
}

.dark {
  color: var(--white);
}

.light {
  color: var(--black);
}