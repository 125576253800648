.wrap {
  font-size: 20px;
  line-height: 30px;
}

.dark {
  color: var(--white);
}

.light {
  color: var(--black);
}