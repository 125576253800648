.builder {
  width: 100%;
  &.dark {
    background: linear-gradient(to bottom, #111114, #1e222c);
  }
  &.light {
    background: var(--grey7);
  }
  padding: 22px;
  border-radius: 6px;
  overflow-y: auto;
}

.wrap {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.item {
  margin-bottom: 30px;
}

.content {
  flex: 1;
}

.deposit {
  margin-bottom: 10px;
}

.configurationError {
  margin-bottom: 15px;
}

.spinner {
  z-index: 5;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: inherit;
  padding: 0;
  border: none;
  background-color: transparent;
}

.text {
  cursor: default;
}

.header {
  margin-bottom: 20px;
}