@use '../../../common/styles/size.scss' as v;

$padding-bottom: 40px;
$padding-horizontal: 40px;
$padding-horizontal-scroll: 34px;
$filters-width: 265px;

.wrap {
  width: 100%;
}

.content {
  flex: 1;
  width: calc(100% - $filters-width - $padding-horizontal);
  -moz-width: calc(100% - $filters-width - $padding-horizontal-scroll);
}

.filters {
  width: $filters-width;
  margin-right: 37px;
  position: sticky;
  top: 90px;
  left: 0;
}

.tr {
  user-select: none;
  cursor: pointer;
}

.description {
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    height: 42px;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
  }
}

@media screen and (max-width: 1120px) {
  .adderBtn {
    display: none;
  }
}