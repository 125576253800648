.description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  border: 0;
  outline: 0;
  color: inherit;
  background-color: transparent;
}

.iconWrapper {
  padding: 7px 8px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  cursor: pointer;
}

.light {
  .description {
    color: var(--black);
    background-color: var(--white);
  }
  .iconWrapper {
    &:hover {
      background-color: var(--grey13);
    }
  }
}

.dark {
  .description {
    color: var(--white);
    background-color: var(--black14);
  }
}