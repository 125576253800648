.root {
  background-color: transparent;
  width: auto;
  height: auto;
  padding: 0;
  border: none;
  &.dark {
    background-color: transparent;
    color: var(--white);
  }
  &.light {
    color: var(--black);
    background-color: transparent;
  }
  &:hover:not(.disabled) {
    background-color: transparent;
  }
  &.active {
    color: transparent
  }
}