.wrap {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.icon {
  color: var(--white);
  margin-bottom: 2px;
}

.dark {
  &.completed {
    background-color: var(--black17);
  }
  &.active {
    color: var(--blue);
    border: 1px solid var(--blue3);
  }
  &.disabled {
    background-color: var(--black);
    color: var(--white);
  }
}

.light {
  &.completed {
    background-color: var(--blue1);
  }
  &.active {
    color: var(--white);
    border: 1px solid var(--white);
  }
  &.disabled {
    background-color: var(--black);
    color: var(--white);
  }
}