.wrap {
  display: inline-block;
  border: none;
  outline: none;
  margin: 0;
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-inline: 0;
}

.dark {
  &.wrap {
    color: var(--white);
    &:hover, &:active {
      color: var(--blue2);
    }
    &:disabled {
      color: var(--black11);
      opacity: 0.5;
    }
  }
}

.light {
  &.wrap {
    color: var(--black);
    &:hover, &:active {
      color: var(--blue5);
    }
    &:disabled {
      color: var(--black);
      opacity: 0.5;
    }
  }
}