.error {
  margin-top: 16px;
}

.value {
  margin-right: 4px;
}

.divider {
  opacity: 0.1;
  background-color: var(--grey2);
  margin: 10px 0;
}

.label {
  opacity: 0.3;
}

.txt {
  font-size: 20px;
}

.light {
  .inputLabel {
    background-color: var(--grey24);
    border: transparent;
  }
  .txt {
    color: var(--blue5);
  }
}

.dark {
  .txt {
    color: var(--blue);
  }
}

.perHour {
  padding-top: 2px;
}