.wrap {
  width: 100%;
}

.dropdown {
  display: none;
}

.dropdownToggle {
  background-color: transparent !important;
  color: var(--blue3) !important;
  font-size: 28px;
  padding: 0;
}

.dropdownMenu {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .item {
    font-size: 28px;
  }
  .tabItem {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: 568px) {
  .tabsWrap {
    display: none;
  }
  .dropdown {
    display: block;
  }
}

@media screen and (max-width: 370px) {
  .item {
    font-size: 21px;
  }
  .dropdownMenu {
    font-size: 16px;
  }
}