.title {
  font-size: 12px;
  margin-bottom: 25px;
}

.content {
  flex: 1;
}

.mrb {
  margin-bottom: 15px;
}

.light {
  color: var(--black);
}

.dark {
  color: var(--white);
}

.footer {
  margin-top: 20px;
}