.accordionToggleWrap {
  font-size: 16px;
  padding-right: 5px;
}

.accordionCollapse {
  overflow-y: auto;
}

.mb6 {
  margin-bottom: 6px;
}

.spacer {
  height: 21px;
}

.input {
  width: 108px;
  height: 31px;
  &.light {
    color: var(--black);
  }
  &.dark {
    color: var(--white);
  }
}

.inputWrap {
  margin-left: 10px;
}

.extports {
  column-gap: 20px;
}

.radio {
  height: 33px;
  align-items: center;
  display: flex;
}

.radioLabel {
  margin-top: 3px;
}