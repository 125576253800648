.wrap {
  :global {
    a {
      text-decoration-line: underline;
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark {
  :global {
    a {
      color: var(--white);
    }
  }
  color: var(--white);
}

.light {
  :global {
    a {
      color: var(--black);
    }
  }
  color: var(--black);
}

