@use '../../common/styles/size.scss' as v;

.wrap {
  height: calc(100vh - #{v.$header-height});
  overflow-y: auto;
  padding: 0 34px 0 40px;
  -moz-padding: 0 40px;
}

@supports (scrollbar-gutter: stable) {
  .wrap {
    scrollbar-gutter: stable;
  }
}

.top {
  height: 90px;
  min-height: 90px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1270px) {
  .wrap {
    padding: 0 14px 0 20px;
    -moz-padding: 0 20px;
  }
}

@media screen and (max-width: 1120px) {
  .top {
    margin-bottom: 0;
  }
}