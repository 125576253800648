.content {
  border-radius: 4px;
  padding: 20px 15px;
}

.dark {
  &.content {
    color: var(--white);
    background-color: var(--black16);
  }
}

.light {
  &.content {
    color: var(--black);
    background-color: var(--white);
  }
}