.offersSelectWrap {
  padding: 15px;
  border-radius: 6px;
  &.offersEmptyWrap {
    min-height: 74px;
  }
}

.title {
  margin-bottom: 6px;
}

.dark {
  .offersSelectWrap {
    background-color: var(--black14);
  }
  .title {
    color: var(--grey2);
  }
}

.light {
  .offersSelectWrap {
    background-color: var(--white);
  }
  .title {
    color: var(--grey8);
  }
}

.or {
  opacity: 0.3;
  margin: 0 20px;
}

.file {
  width: 174px;
}

.content {
  position: relative;
}

.mr {
  margin-bottom: 10px;
}

.notification {
  margin-top: 10px;
}

.popover {
  border-radius: 6px;
  padding: 10px 14px;
  width: 261px !important;
  &:global(.bs-popover-top > .popover-arrow) {
    transform: translate(120px, 0px) !important;
  }
}

.popoverText {
  padding: 0;
  line-height: 19.2px;
  font-weight: 300;
}