.addFile {
  margin-left: 11px;
}

.file {
  width: 100%;
}

.dark {
  .addFile {
    color: var(--white);
  }
  .icon {
    color: var(--white);
  }
}

.light {
  .addFile {
    color: var(--black);
  }
  .icon {
    color: var(--black);
  }
}

