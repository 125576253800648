.phrase {
  float: right;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
}

.agreement {
  margin-top: 8px;
  font-size: 14px;
}

.modeswitcher {
  margin-bottom: 16px;
}

.mode {
  margin-left: 20px;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.3;
  &-active {
    opacity: 1;
    color: var(--blue3);
  }
  &-zero {
    margin-left: 0;
  }
}

.input {
  height: 86px;
}

.light {
  color: var(--black);
}

.dark {
  color: var(--white);
}