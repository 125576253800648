.wrap {
  padding: 0 15px 0;
}

.btn {
  width: 163px;
  height: 41px;
}

.text {
  font-size: 12px;
  opacity: 0.3;
  margin: 10px 0 0;
}

.link {
  font-weight: 600;
}

.link, .link:visited, .link:hover {
  text-decoration: underline;
  color: inherit;
}