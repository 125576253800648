.createdIcon {
  color: var(--green);
  margin-right: 7px;
}

.createdText {
  color: var(--green);
}

.queueIcon {
  color: var(--white);
  margin-right: 7px;
}

.errorIcon {
  color: var(--red);
  margin-right: 7px;
}

.errorText {
  color: var(--red);
}

.queueText {
  color: var(--white);
}

.progressText {
  color: var(--grey15);
}

.spinner {
  width: 12px;
  height: 12px;
  border-width: 2px;
  margin-right: 7px;
}

.light {
  .queueText {
    color: var(--grey15);
  }
  .queueIcon {
    color: var(--grey15);
  }
}