.wrap {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.item {
  &:not(:last-child) {
    margin-right: 30px;
  }
}

.active {
  opacity: 1 !important;
}

.xs {
  font-size: 14px;
}

.sm {
  font-size: 16px;
}

.md {
  font-size: 26px;
}

.lg {
  font-size: 38px;
}

.xl {
  font-size: 26px;
}

.aligntLeft {
  padding-left: 0;
}

.dark {
  .active {
    color: var(--blue2);
  }
}

.light {
  .active {
    color: var(--blue5);
  }
}