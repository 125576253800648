.accordion {
  background-color: transparent;
}

.fullWidth {
  width: 100%;
}

.accordion.open {
  padding-bottom: 10px;
}

.light {
  color: var(--black);
  &.accordion {
    border-bottom: 1px solid var(--grey17);
  }
}

.dark {
  color: var(--white);
  &.accordion {
    border-bottom: 1px solid var(--grey1);
  }
}