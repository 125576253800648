.content {
  width: 100%;
  padding: 12px 30px 12px 22px;
  text-align: center;
  border-radius: 4px;
  flex: 1;
  &.default {
    &.light {
      background: var(--blue14);
      color: var(--black);
    }
    &.dark {
      background: var(--black36);
      color: var(--white);
    }
  }
  &.orange {
    &.dark {
      background: rgba(218, 101, 24, 0.15);
    }
    &.light {
      background: rgba(218, 101, 24, 0.03);
    }
    color: var(--orange1);
  }
}

.icon {
  margin-left: 15px;
  cursor: pointer;
  color: inherit;
}

.children {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.childrenHtml {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 874px;
  flex: 1;
  hyphens: auto;
  white-space: unset !important;
}

.childrenHtml > span > a, .childrenHtml > span > a:visited, .childrenHtml > span > a:hover {
  color: inherit;
  font-weight: 500;
}

.dark {
  &.childrenHtml > span > a, &.childrenHtml > span > a:visited, &.childrenHtml > span > a:hover {
    color: var(--white);
  }
}

.content:not(:first-child) {
  margin-top: 6px;
}

@media screen and (max-width: 1120px) {
  .childrenHtml {
    width: 650px;
  }
}

@media screen and (max-width: 1000px) {
  .childrenHtml {
    width: unset;
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .content {
    padding: 12px 42px;
  }
}

@media screen and (max-width: 480px) {
  .content {
    font-size: 12px;
  }
}
