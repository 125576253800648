.wrap {
  padding: 22px;
  width: 100%;
  border-radius: 6px;
  height: 100%;
  overflow-y: auto;
}

.header {
  margin-bottom: 20px;
}

.dark {
  background: linear-gradient(to bottom, #111114, #1e222c);
}

.light {
  background: var(--grey7);
}