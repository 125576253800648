.dropzone {
  display: flex;
  height: 50px;
  cursor: pointer;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  &-error {
    border: 1px dashed var(--red2);
    background-color: var(--red1);
  }
}

.dark {
  &.dropzone {
    border: 1px dashed var(--grey4);
  }
}

.light {
  &.dropzone {
    border: 1px dashed var(--grey11);
  }
}
