@use '../../../../common/styles/size.scss' as v;

.wrap {
  height: v.$height-table-filters;
  margin-bottom: v.$mb-table-filters;
}

.mr {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.dropdownToggle {
  background-color: transparent;
}

@media screen and (max-width: 1120px) {
  .switcher {
    display: none;
  }
  .mr {
    &:not(:last-child) {
      margin-right: 0;
    }
  }
}
