$stepper-width: 234px;
$form-stepper-width: 508px;

.wrap {
  height: 100%;
}

.body {
  padding: 0;
  width: calc($stepper-width + $form-stepper-width);
  height: 100%;
}

.content {
  width: calc($stepper-width + $form-stepper-width);
  background-color: transparent;
  border: none;
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.stepperWrap {
  width: $stepper-width;
  margin-right: 2px;
  padding: 71px 0 20px;
  border-radius: 6px;
  height: 100%;
  overflow-y: auto;
}

.formStepperWrap {
  width: $form-stepper-width;
  height: 100%;
  overflow-y: auto;
}

.dark {
  .stepperWrap {
    background: linear-gradient(to bottom, #111114, #1e222c);
  }
}

.light {
  .stepperWrap {
    background: var(--grey7);
  }
}

.btnClose {
  z-index: 10;
}