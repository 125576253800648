.link {
  text-decoration: none;
  position: relative;
  line-height: 21px;
  width: fit-content;
  cursor: pointer;
}

.link::after {
  position: absolute;
  content: '';
  width: 100%;
  opacity: 0.3;
  top: 22px;
  right: 0;
  left: 0;
}

.link, .link:active, .link:hover, .link:visited {
  color: var(--white);
}

.dark {
  &.link, &.link:active, &.link:hover, &.link:visited {
    color: var(--white);
  }
  &.link::after {
    border-bottom: 1px dashed var(--white);
  }
}

.light {
  &.link, &.link:active, &.link:hover, &.link:visited {
    color: var(--black);
  }
  &.link::after {
    border-bottom: 1px dashed var(--black);
  }
}