.list {
  height: 100%;
  overflow-y: auto;
}

.accordionToggleWrap {
  font-size: 16px;
  padding-right: 5px;
}

.accordionCollapse {
  overflow-y: auto;
}

.container:not(:first-child) {
  margin-top: 12px;
}

.spacer {
  height: 21px;
}