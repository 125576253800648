.wrap {
  height: 100%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1120px) {
  .wrap {
    display: none;
  }
}
