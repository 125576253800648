.wrap {
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.dark {
  &.wrap {
    color: var(--white);
    &:hover, &:active {
      color: var(--blue2);
    }
    &.disabled {
      color: var(--black11);
    }
  }
}

.light {
  &.wrap {
    color: var(--black);
    &:hover, &:active {
      color: var(--blue5);
    }
    &.disabled {
      color: var(--black11);
    }
  }
}