.btn {
  display: flex;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  padding: 0;
  &:focus {
    outline: none;
  }
  overflow: hidden;
}

.icon {
  transition: transform 0.2s ease-in-out;
}

.inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0 16px 0;
}

.dark {
  &.btn {
    background-color: transparent;
    color: var(--white);
  }
  .icon {
    fill: var(--white);
  }
  .title {
    color: var(--white);
  }
}

.light {
  &.btn {
    background-color: transparent;
    color: var(--black);
  }
  .icon {
    fill: var(--black);
  }
  .title {
    color: var(--black);
  }
}

.iconWrap {
  margin-right: 12px;
}

.left {
  display: flex;
  align-items: center;
}

.open {
  .icon {
    color: var(--blue3);
    transform: rotate(-180deg);
  }
}

.counter {
  position: static;
  margin-right: 10px;
}