.skel {
  height: 35px;
  min-height: 35px;
  width: 100%;
}

.lines {
  width: 100%;
  row-gap: 4px;
  user-select: none;
}

.dark {
  .lineChildrenBase {
    color: rgba(255, 255, 255, 0.7);
  }
}

@media screen and (max-width: 768px) {
  .lines {
    margin-left: -40px;
    width: calc(100% + 80px);
  }
}