.wrap {
  padding: 16px;
  font-size: 12px;
  &.dark {
    color: var(--white);
    &.active {
      background: linear-gradient(90deg, rgba(249, 249, 249, 0.1) 0%, rgba(249, 249, 249, 0) 97.65%);
      color: var(--blue1);
    }
  }
  &.light {
    color: var(--black);
    &.active {
      background: var(--blue5);
      color: var(--white);
    }
  }
}

.disabled {
  background: transparent;
  opacity: 0.3;
}

