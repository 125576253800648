.title {
  font-size: 12px;
  margin-bottom: 25px;
}

.content {
  flex: 1;
}

.notification {
  margin-top: 40px;
}

.checkbox {
  margin-bottom: 25px;
}

.light {
  .title {
    color: var(--black);
  }
}

.dark {
  .title {
    color: var(--white);
  }
}

.footer {
  margin-top: 20px;
}