.wrap {
  border: 1px solid var(--black5);
  height: 86px;
  padding: 8px;
  border-radius: 4px;
}

.phrase {
  flex: 1;
  margin-right: 8px;
}

.btnGenerate {
  width: 25px;
  height: 25px;
  padding: 0;
}

.light {
  &.wrap {
    background-color: var(--white);
  }
  .btnGenerate {
    color: var(--blue5);
    background-color: var(--grey12);
  }
  .clipboard {
    color: var(--blue5);
    background-color: var(--grey12);
  }
}